import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { FormController } from '../../components/commons/form/controller/form-controller';
import Intro from '../../components/commons/intro/intro';
import { DsrAttendanceListModule } from './attendance/attendance.module';
import { DsrCashBreakdownListModule } from './cash-breakdown/cash-breakdown.module';
import { DsrCashOverListModule } from './cash-over/cash-over.module';
import { DsrCashShortListModule } from './cash-short/cash-short.module';
import { DsrCollectionListModule } from './collection/collection.module';
import { DsrDepositListModule } from './deposit/deposit.module';
import { DsrRecordsFilter } from './dsr-records-filter';
import { useInitialFilterState } from './dsr-records-filter.state';
import { DsrExpenseListModule } from './expense/expense.module';
import { DsrInventoryListModule } from './inventory/inventory.module';
import { DsrItemReceiptListModule } from './item-receipt/item-receipt.module';
import { DsrPumpListModule } from './pump/pump.module';
import { DsrShiftListModule } from './shift/shift.module';
import { DsrTransactionListModule } from './transaction/transaction.module';

export function DsrRecordsModule() {
  const [currentTab, setCurrentTab] = React.useState("transaction");

  const initialState = useInitialFilterState();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const dsrTabs = React.useMemo(
    () => [
      {
        value: 'shift',
        label: 'Shift',
        component: <DsrShiftListModule />,
      },
      {
        value: 'transaction',
        label: 'Transaction',
        component: <DsrTransactionListModule />,
      },
      {
        value: 'pump',
        label: 'Pump',
        component: <DsrPumpListModule />,
      },
      {
        value: 'item-receipt',
        label: 'Item Receipt',
        component: <DsrItemReceiptListModule />,
      },
      {
        value: 'inventory',
        label: 'Inventory',
        component: <DsrInventoryListModule />,
      },
      {
        value: 'expense',
        label: 'Expense',
        component: <DsrExpenseListModule />,
      },
      {
        value: 'deposit',
        label: 'Deposit',
        component: <DsrDepositListModule />,
      },
      {
        value: 'collection',
        label: 'Collection',
        component: <DsrCollectionListModule />,
      },
      {
        value: 'cash-short',
        label: 'Cash Short',
        component: <DsrCashShortListModule />,
      },
      {
        value: 'cash-over',
        label: 'Cash Over',
        component: <DsrCashOverListModule />,
      },
      {
        value: 'cash-breakdown',
        label: 'Cash Breakdown',
        component: <DsrCashBreakdownListModule />,
      },
      {
        value: 'attendance',
        label: 'Attendance',
        component: <DsrAttendanceListModule />,
      },
    ],
    []
  );

  const component = React.useMemo(() => {
    const tab = dsrTabs.find((x) => x.value === currentTab);
    return tab?.component || 'Unknown Tab';
  }, [dsrTabs, currentTab]);

  return (
    <>
      <Intro title="DSR Records" subtitle="List of all records uploaded" />
      <FormController
        useFormProps={{
          defaultValues: initialState,
        }}
      >
        <Box sx={{ mb: "20px" }}>
          <DsrRecordsFilter />
        </Box>
        <Box>
          <Box mb={2}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-scrollButtons': {
                  color: 'primary.main', // Change to your desired color
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem', // Adjust the icon size (2rem is bigger, adjust as needed)
                },
              }}
            >
              {dsrTabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          {component}
        </Box>
      </FormController>
    </>
  );
}
